import React, {useCallback, useRef, useState} from "react";
import { StylesManager, Model } from "survey-core";
import * as SurveyCore from "survey-core";
import { Survey } from "survey-react-ui";
import json from "../surveyData/cube.json"
import * as widgets from "surveyjs-widgets";
import "survey-core/defaultV2.css";
import "../cube_survey.css";
import $ from "jquery";

window["$"] = window["jQuery"] = $;
StylesManager.applyTheme("defaultV2");
require("emotion-ratings/dist/emotion-ratings.js");
widgets.emotionsratings(SurveyCore);

function SurveyComponent() {

    // useRef enables the Model object to persist between state changes
    const survey = useRef(new Model(json)).current;
    const [surveyResults, setSurveyResults] = useState("");
    const [isSurveyCompleted, setIsSurveyCompleted] = useState(false);

    const visiblePageIndex = survey.currentPageNo;
    survey.currentPage = visiblePageIndex;

    const displayResults = useCallback((sender) => {
        setSurveyResults(JSON.stringify(sender.data, null, 4));
        setIsSurveyCompleted(true);
    }, []);

    survey.onComplete.add(displayResults);

    return (
        <>
            <Survey model={survey} id="surveyContainer" />
            {isSurveyCompleted && (
                <>
                    <p>Result JSON:</p>
                    <code style={{ whiteSpace: 'pre' }}>
                        {surveyResults}
                    </code>
                </>
            )
            }
        </>
    );
}
export default SurveyComponent;